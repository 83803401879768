import Link from 'next/link';
import Image from 'next/image';
import {globalBlurDataUrl} from '../../lib/globals';

const EpisodeCard = (props) => {
    const {
        title: {rendered: title},
        acf: {episode_cover_image: {link: episode_cover_image = ''} = {}},
        slug,
    } = props;

    return (
        <div className="ep-card">
            <Link href={`/addiction-talk/${slug}`} title={title}>
                <div className="ep-card__image">
                    <Image
                        src={
                            episode_cover_image
                                ? episode_cover_image
                                : 'https://admin.americanaddictioncenters.org/wp-content/uploads/2021/01/Addiction-Talk-LP-Hero-1-1.jpg'
                        }
                        fill
                        style={{
                            objectFit: `${!!episode_cover_image ? 'contain' : 'cover'}`,
                        }}
                        placeholder="blur"
                        blurDataURL={globalBlurDataUrl}
                        alt={title}
                        sizes="100vw, 33vw"
                    />
                </div>
            </Link>
            <div className="ep-card__title" dangerouslySetInnerHTML={{__html: title}} />
            <style jsx>
                {`
                    .ep-card {
                    }
                    .ep-card__image {
                        height: 205px;
                        margin-bottom: 8px;
                        position: relative;
                        overflow: hidden;
                        background: #000;
                        border-radius: 8px;
                    }
                    .ep-card__title {
                        padding: 0 4px;
                    }
                `}
            </style>
        </div>
    );
};
export default EpisodeCard;
