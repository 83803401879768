import MaxWidth from '../MaxWidth';
import Image from 'next/image';
import {BREAKPOINT} from 'aac-components/utils/styles';

const MediaOutlets = ({outlets}) => {
    return (
        <MaxWidth>
            <div className="media-outlets">
                <div className="media-outlets__title">Addiction Talk Featured In</div>
                <div className="media-outlets__items">
                    {Array.isArray(outlets) &&
                        outlets.map((item, index) => {
                            return (
                                <div className="media-outlets__img" key={index}>
                                    <Image
                                        src={item?.media_logo}
                                        alt={item?.name}
                                        fill
                                        style={{objectFit: 'contain'}}
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
            <style jsx>
                {`
                    .media-outlets {
                        margin-bottom: 0;
                        padding: 0 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .media-outlets {
                            margin-bottom: 40px;
                            padding: 0;
                        }
                    }
                    .media-outlets__title {
                        text-align: center;
                        padding-bottom: 20px;
                        font-size: 24px;
                        font-weight: bold;
                    }
                    .media-outlets__img {
                        position: relative;
                        width: 100%;
                        min-width: 100px;
                        height: 80px;
                    }
                    .media-outlets__items {
                        display: grid;
                        grid-template-columns: repeat(7, 1fr);
                        grid-gap: 15px;
                        max-width: 100%;
                        margin: 0 auto;
                        max-width: 100%;
                        padding: 20px 15px;
                        overflow-x: scroll;
                        border-top: 2px solid var(--gray-300);
                        border-bottom: 2px solid var(--gray-300);
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .media-outlets__items {
                            display: grid;
                            grid-template-columns: repeat(7, 1fr);
                            align-items: center;
                            justify-content: space-between;
                            grid-gap: 15px 60px;
                            overflow-x: hidden;
                        }
                    }
                `}
            </style>
        </MaxWidth>
    );
};
export default MediaOutlets;
