import {useContext} from 'react';
import AppContext from '../AppContext';
import MaxWidth from '../MaxWidth';
import Image from 'next/image';
import {BREAKPOINT} from 'aac-components/utils/styles';

const Hero = (props = {}) => {
    const {title: {rendered: title = ''} = {}, acf: {hero_copy = ''} = {}} = props;

    const {isMobile} = useContext(AppContext);

    return (
        <div className="hero">
            <Image
                src={`/static/addiction-talk/addiction-talk-hero__${
                    isMobile ? 'mobile' : 'desktop'
                }.jpg`}
                fill
                alt="Addiction Talk"
                style={{objectFit: 'cover'}}
                priority={true}
                sizes="100vw"
            />
            <MaxWidth>
                <div className="hero__content">
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: title}} />
                        <div
                            className="hero__copy"
                            dangerouslySetInnerHTML={{__html: hero_copy}}
                        />
                        <div className="hero__buttons">
                            <a
                                className="hero__button"
                                href="https://www.youtube.com/playlist?list=PLyMxbZZFFULJNXOeRHjJWfUiPZPGS8BzD"
                                target="_blank"
                                title="Watch on YouTube">
                                Watch on YouTube
                            </a>
                        </div>
                    </div>
                    <div className="hero__content--awards">
                        <div>
                            <Image
                                src="/static/addiction-talk/Telly_45th_Winners_Badges_silver_winner.webp"
                                alt="45th Annual Telly Awards Silver Badge Winner"
                                sizes="(max-width: 350px) 100vw"
                                width={0}
                                height={0}
                                style={{height: '110px', width: 'auto'}}
                            />
                        </div>
                        <div>
                            <Image
                                src="/static/addiction-talk/Telly_44th_Winners_Badges_silver_winner.png"
                                alt="44th Annual Telly Awards Silver Badge Winner"
                                sizes="(max-width: 350px) 100vw"
                                width={0}
                                height={0}
                                style={{height: '110px', width: 'auto'}}
                            />
                        </div>
                        <div>
                            <Image
                                src="/static/addiction-talk/bronze-winner.png"
                                alt="43th Annual Telly Awards Bronze Badge Winner"
                                sizes="(max-width: 350px) 100vw"
                                width={0}
                                height={0}
                                style={{height: '110px', width: 'auto'}}
                            />
                        </div>
                    </div>
                </div>
            </MaxWidth>
            <style jsx>
                {`
                    .hero {
                        position: relative;
                        padding: 0;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            margin-bottom: 80px;
                        }
                    }
                    .hero__content {
                        position: relative;
                        padding: 40px 15px;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content {
                            padding: 100px 0;
                            grid-template-columns: auto 450px;
                            grid-gap: 160px;
                            align-items: center;
                        }
                    }
                    h1 {
                        margin-bottom: 24px;
                    }
                    .hero__copy {
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    .hero__buttons {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__buttons {
                            grid-template-columns: 200px 200px;
                            grid-gap: 24px;
                        }
                    }
                    .hero__button {
                        background: var(--error);
                        height: 45px;
                        border-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        font-weight: bold;
                        color: #fff;
                        border: 2px solid var(--error);
                        box-shadow: var(--box-shadow);
                    }
                    .hero__button.invert {
                        background: #fff;
                        color: var(--error);
                    }
                    .hero__content--awards {
                        display: grid;
                        grid-template-columns: repeat(1, 1fr);
                        grid-gap: 24px;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__content--awards {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default Hero;
