import {BREAKPOINT} from 'aac-components/utils/styles';
import MaxWidth from '../MaxWidth';
import EpisodeCard from './EpisodeCard';
import Pagination from 'aac-components/components/Pagination';

const EpisodesGrid = ({episodes, total, currentPage}) => {
    return (
        <MaxWidth>
            <div className="ep-grid">
                {Array.isArray(episodes) &&
                    episodes.map((ep) => {
                        return (
                            <div key={ep?.id}>
                                <EpisodeCard {...ep} />
                            </div>
                        );
                    })}
            </div>
            {episodes?.length !== 1 && total > 9 && (
                <div className="pagination">
                    <Pagination
                        perPage={9}
                        total={Number(total)}
                        currentPage={Number(currentPage)}
                        urlBase={`/addiction-talk`}
                    />
                </div>
            )}
            <style jsx>
                {`
                    .ep-grid {
                        display: grid;
                        grid-template-columns: 1fr;
                        padding: 0 15px;
                        grid-gap: 16px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .ep-grid {
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 30px;
                            padding: 0 0 40px 0;
                        }
                    }
                    .pagination {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 40px;
                    }
                `}
            </style>
        </MaxWidth>
    );
};
export default EpisodesGrid;
