import Layout from '../../components/Layout';
import {getPageByPath} from '../../lib/post';
import fetch from 'aac-components/utils/fetch';
import {BREAKPOINT} from '../../lib/styles';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';

import Hero from '../../components/AddictionTalk/Hero';
import EpisodesGrid from '../../components/AddictionTalk/EpisodesGrid';
import SocialBanner from '../../components/AddictionTalk/EmailReminders';
import AboutHost from '../../components/AddictionTalk/AboutHost';
import UpcomingEpisodes from '../../components/AddictionTalk/UpcomingEpisodes';
import MediaOutlets from '../../components/AddictionTalk/MediaOutlets';

import {useEffect, useRef, useState} from 'react';
import MaxWidth from '../../components/MaxWidth';
import OutsideClick from 'aac-components/components/OutsideClick';
import {useRouter} from 'next/router';

const perPage = 9;
const titleRegex = /^[^:]+:\s*/g;

const AddictionTalkHub = (props) => {
    // data
    const {
        data: {
            addiction_talk: {all_posts = [], upcoming = []} = {},
            acf: {media_outlets = []} = {},
        },
        episodesData = [],
        currentPage,
        totalPosts = 0,
    } = props;

    // router
    const {query} = useRouter();
    const isPaginated = Boolean(query?.page);

    // refs
    const episodesRef = useRef(null);

    // filter
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filteredPost, setFilteredPost] = useState(null);
    const handleFilter = async (id) => {
        // if the post they're filtering for is already in episodesData, don't make another fetch
        const foundPostInEpisodes = episodesData.filter((ep) => ep?.id === id);

        let response = {};
        let filteredPost = [];

        if (foundPostInEpisodes?.id) {
            filteredPost = foundPostInEpisodes;
        } else {
            const url = `/wordpress-api/pages?include[]=${id}&_fields=title,id,slug,date,featured_image,excerpt,acf`;
            try {
                response = await fetch(url);
                filteredPost = await response.json();
            } catch (e) {
                console.warn(e);
            }
        }

        setFilteredPost(filteredPost);
        setIsFilterOpen(false);
    };

    // scroll
    useEffect(() => {
        if (isPaginated) {
            episodesRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        setFilteredPost(null);
    }, [isPaginated]);

    const filteredPostTitle = filteredPost?.[0]?.title?.rendered?.replace(titleRegex, '');

    return (
        <Layout>
            <Hero {...props?.data} />
            {Array.isArray(upcoming) && upcoming?.length >= 1 && (
                <UpcomingEpisodes episodes={upcoming} />
            )}
            <MediaOutlets outlets={media_outlets} />
            <MaxWidth>
                <div className="ep-grid__header">
                    <div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                color: 'var(--error)',
                                textTransform: 'uppercase',
                                fontSize: '14px',
                            }}>
                            Episode List
                        </div>
                        <div
                            style={{fontWeight: 'bold', fontSize: '30px'}}
                            dangerouslySetInnerHTML={{
                                __html: filteredPost ? filteredPostTitle : 'All Episodes',
                            }}
                        />
                    </div>
                    <div />
                    <div className="filter">
                        <OutsideClick onClick={() => setIsFilterOpen(false)}>
                            <div
                                className="filter__placeholder"
                                onClick={() =>
                                    setIsFilterOpen((prevInputState) => !prevInputState)
                                }>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: filteredPost
                                            ? filteredPostTitle
                                            : 'Filter by guest name',
                                    }}
                                />
                                <div>
                                    <IconChevron
                                        fill="var(--gray-400)"
                                        rotate={isFilterOpen ? '-180' : '0'}
                                    />
                                </div>
                            </div>
                            {isFilterOpen && (
                                <div className="filter__options">
                                    {filteredPost && (
                                        <div
                                            style={{fontWeight: 'bold'}}
                                            onClick={() => {
                                                setFilteredPost(null);
                                                setIsFilterOpen(false);
                                            }}>
                                            View All
                                        </div>
                                    )}
                                    {Array.isArray(all_posts) &&
                                        all_posts.map((item) => {
                                            return (
                                                <div
                                                    key={item?.id}
                                                    onClick={() =>
                                                        handleFilter(item?.id)
                                                    }>
                                                    {item?.title.replace(titleRegex, '')}
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </OutsideClick>
                    </div>
                </div>
            </MaxWidth>
            <div className="scroll-block" ref={episodesRef}>
                <EpisodesGrid
                    episodes={filteredPost || episodesData}
                    total={totalPosts}
                    currentPage={currentPage}
                />
            </div>
            <SocialBanner />
            <AboutHost />
            <style jsx>
                {`
                    .ep-grid__header {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 8px;
                        align-items: flex-end;
                        justify-content: space-between;
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .ep-grid__header {
                            padding: 40px 0 0 0;
                            grid-template-columns: repeat(3, 1fr);
                            display: grid;
                            grid-gap: 30px;
                            margin-bottom: 16px;
                        }
                    }
                    .filter {
                        position: relative;
                    }
                    .filter__placeholder {
                        background: var(--gray-100);
                        border: 1px solid var(--gray-200);
                        padding: 8px;
                        border-radius: 4px;
                        font-size: 14px;
                        min-width: 100%;
                        height: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                    }
                    .filter__options {
                        position: absolute;
                        z-index: 1;
                        background: #fff;
                        max-height: 250px;
                        overflow-y: scroll;
                        box-shadow: var(--box-shadow);
                    }
                    .filter__options > div {
                        border-bottom: 1px solid var(--gray-100);
                        padding: 8px;
                        display: flex;
                        align-items: center;
                    }
                    .filter__options > div:hover {
                        cursor: pointer;
                        background: var(--gray-100);
                    }
                    .scroll-block {
                        scroll-margin: 300px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .scroll-block {
                            scroll-margin: 300px;
                        }
                    }
                `}
            </style>
        </Layout>
    );
};
export default AddictionTalkHub;

export async function getServerSideProps(context) {
    let data = await getPageByPath(
        context,
        '?_fields=related_posts,acf,byline_info,content,excerpt,featured_image,guide_learn_meta,id,link,meta,modified,page_meta,parent,slug,tags,template,title,type,yoast_head,addiction_talk',
    );
    const id = data?.id || 0;

    // for getting paginated posts under /addiction-talk
    const pageNumber = context?.query?.page || 1;
    const isFirstPage = pageNumber == 0 || pageNumber == 1;
    const offset = isFirstPage ? 0 : (pageNumber - 1) * perPage + 1;
    const url = `/wordpress-api/pages?per_page=${perPage}&parent=80296&offset=${offset}&_fields=title,id,slug,date,featured_image,excerpt,acf`;
    let response = {};
    let episodesData = [];
    try {
        response = await fetch(url);
        episodesData = await response.json();
    } catch (e) {
        console.warn(e);
    }

    // set 404 status code
    if (id === 0 || data?.type !== 'page') {
        context.res.statusCode = 404;
    }
    return {
        props: {
            data,
            episodesData,
            currentPage: pageNumber,
            totalPosts: Number(response.headers.get('x-wp-total')) || 0,
        },
    };
}
