import {BREAKPOINT} from 'aac-components/utils/styles';
import Image from 'next/image';
import MaxWidth from '../MaxWidth';

const AboutHost = () => {
    return (
        <MaxWidth>
            <div className="container">
                <div className="host">
                    <div className="host__title">About The Host</div>
                    <div className="host__bio">
                        As a former TV health journalist and current talk show host, Joy
                        Sutton is a master communicator with over 20 years of media
                        experience. Joy has been engaging audiences as far back as she can
                        remember, including a theatrical performance at New York’s famed
                        Apollo Theater. Joy’s media background includes serving as an
                        anchor/health reporter for a CBS affiliate, producing stories that
                        aired on Entertainment Tonight and covering former President Bill
                        Clinton’s impeachment hearings. She has also produced special
                        reports from world-renowned medical centers, including the CDC and
                        Walter Reed Army Medical Center. She also serves as the VP of
                        Brand and Communications for American Addiction Centers. Joy is a
                        proud graduate of Howard University and Virginia Tech.
                    </div>
                    <div className="host__hashtag">#WeRecoverTogether.</div>
                </div>
                <div>
                    <div className="host__image">
                        <Image
                            src="/static/joy-sutton.png"
                            fill
                            style={{
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                            alt="Host, Joy Sutton"
                            sizes="100vw, 33vw"
                        />
                    </div>
                    <div className="host__name text-xl bold">
                        Joy Sutton, Host of Addiction Talk.
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .container {
                        padding: 40px 15px;
                        display: grid;
                        grid-template-columns: 1fr;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            padding: 80px 0;
                            grid-template-columns: auto 350px;
                            grid-gap: 165px;
                        }
                    }
                    .host__title {
                        color: var(--error);
                        margin-bottom: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    .host__bio {
                        font-size: 18px;
                        line-height: 28px;
                        color: var(--gray-400);
                        margin-bottom: 16px;
                    }
                    .host__hashtag {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .host__hashtag {
                            margin-bottom: 0;
                        }
                    }
                    .host__image {
                        position: relative;
                        width: 100%;
                        height: 340px;
                        margin-bottom: 16px;
                        box-shadow: var(--box-shadow);
                        border-radius: 8px;
                    }
                `}
            </style>
        </MaxWidth>
    );
};
export default AboutHost;
