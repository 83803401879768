import {BREAKPOINT} from 'aac-components/utils/styles';
import Image from 'next/image';
import {useContext} from 'react';
import MaxWidth from '../MaxWidth';
import AppContext from '../AppContext';
import IconYoutube from 'aac-components/components/Icons/Social/IconYoutube';
import IconFacebook from 'aac-components/components/Icons/Social/IconFacebook';
import IconLinkedin from 'aac-components/components/Icons/Social/IconLinkedin';

const SocialBanner = () => {
    const {isMobile} = useContext(AppContext);

    return (
        <div className="container">
            <Image
                src={`/static/addiction-talk/social-banner-bg__${
                    isMobile ? 'desktop' : 'desktop'
                }.jpg`}
                fill
                style={{objectFit: 'cover'}}
                alt="Addiction Talk"
                sizes="100vw"
            />
            <MaxWidth>
                <div className="social-banner">
                    <div className="social-banner__email">
                        <div className="social-banner__title">Email Reminders</div>
                        <a
                            href="https://mcqldh1yyxhv-t9657wsn6ptq-xy.pub.sfmc-content.com/42ayrbm2zlc"
                            target="_blank"
                            title="Sign Me Up">
                            Sign Me Up
                        </a>
                    </div>
                    <div className="social-banner__awards">
                        <a
                            href="https://www.tellyawards.com/winners/2024/online/series-non-scripted/addiction-talk-live/313206/"
                            target="_blank"
                            title="45th Annual Telly Awards Silver Badge Winner">
                            <Image
                                src="/static/addiction-talk/Telly_45th_Winners_Badges_silver_winner.webp"
                                alt="44th Annual Telly Awards Silver Badge Winner"
                                sizes="100vw, 33vw"
                                width={0}
                                height={0}
                                style={{minHeight: '100px', width: 'auto'}}
                            />
                        </a>
                        <a
                            href="https://www.tellyawards.com/winners/2023/online/series-webseries-non-scripted/addiction-talk-live/288881"
                            target="_blank"
                            title="44th Annual Telly Awards Silver Badge Winner">
                            <Image
                                src="/static/addiction-talk/Telly_44th_Winners_Badges_silver_winner.png"
                                alt="44th Annual Telly Awards Silver Badge Winner"
                                sizes="100vw, 33vw"
                                width={0}
                                height={0}
                                style={{minHeight: '100px', width: 'auto'}}
                            />
                        </a>
                        <a
                            href="https://www.tellyawards.com/winners/2022/online/series-webseries-non-scripted/addiction-talk-live/272160"
                            target="_blank"
                            title="43th Annual Telly Awards Bronze Badge Winner">
                            <Image
                                src="/static/addiction-talk/bronze-winner.png"
                                alt="43th Annual Telly Awards Bronze Badge Winner"
                                sizes="100vw, 33vw"
                                width={0}
                                height={0}
                                style={{minHeight: '100px', width: 'auto'}}
                            />
                        </a>
                    </div>
                    <div>
                        <div className="social-banner__title">
                            Watch Live and <br /> Follow Us
                        </div>
                        <div className="social-banner__socials">
                            <a
                                href="https://www.youtube.com/playlist?list=PLyMxbZZFFULJNXOeRHjJWfUiPZPGS8BzD"
                                title="YouTube"
                                target="_blank">
                                <div>
                                    <IconYoutube width="40" height="40" />
                                </div>
                                <div>YouTube</div>
                            </a>
                            <a
                                href="https://www.facebook.com/AmericanAddictionCenters/"
                                title="Facebook"
                                target="_blank">
                                <div>
                                    <IconFacebook width="40" height="40" />
                                </div>
                                <div>Facebook</div>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/american-addiction-centers"
                                title="LinkedIn"
                                target="_blank">
                                <div>
                                    <IconLinkedin width="40" height="40" />
                                </div>
                                <div>LinkedIn</div>
                            </a>
                        </div>
                    </div>
                </div>
            </MaxWidth>
            <style jsx>
                {`
                    .container {
                        width: 100%;
                        position: relative;
                    }

                    .social-banner {
                        position: relative;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 50px;
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .social-banner {
                            grid-template-columns: 1fr 2fr 1fr;

                            align-items: center;
                            padding: 40px 0;
                        }
                    }
                    .social-banner__title {
                        font-size: 22px;
                        margin-bottom: 24px;
                        font-weight: bold;
                        text-align: center;
                        line-height: 28px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .social-banner__title {
                            text-align: left;
                        }
                    }
                    .social-banner__email a {
                        background: var(--error);
                        color: #fff;
                        padding: 12px 56px;
                        border-radius: 8px;
                        text-decoration: none;
                        min-width: 100%;
                        display: block;
                        text-align: center;
                    }
                    .social-banner__socials {
                        display: grid;
                        grid-template-columns: repeat(3, auto);
                        justify-content: flex-start;
                        grid-gap: 24px;
                        margin: 0 auto;
                        max-width: fit-content;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .social-banner__socials {
                            margin: 0;
                        }
                    }
                    .social-banner__socials a > div:first-child {
                        width: 56px;
                        height: 56px;
                        border-radius: 8px;
                        background: #fff;
                        box-shadow: var(--box-shadow);
                        border: 1px solid var(--gray-200);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 8px;
                    }
                    .social-banner__socials a {
                        color: var(--gray-500);
                        font-size: 12px;
                        text-decoration: none;
                    }
                    .social-banner__awards {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                        margin: 0 auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .social-banner__awards {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                        }
                    }
                    .social-banner__awards > a {
                        display: block;
                    }
                `}
            </style>
        </div>
    );
};
export default SocialBanner;
