import MaxWidth from '../MaxWidth';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import Image from 'next/image';
import {BREAKPOINT} from 'aac-components/utils/styles';
import Link from 'next/link';
import {isMappableArray} from 'aac-components/utils/helpers';

const truncate = (str, n) => {
    return str?.length > n ? str.slice(0, n - 1) + '&hellip;' : str;
};

const UpcomingEpisodes = ({episodes}) => {
    return (
        <MaxWidth>
            <div className="container">
                {isMappableArray(episodes) &&
                    episodes.map((ep) => {
                        const {
                            episode_cover_image: {link: episode_cover_image = ''} = {},
                            hero_description = '',
                            air_date = '',
                            slug,
                            title = '',
                        } = ep;
                        const date = format(parse(air_date), 'dddd, MMMM D h:mm a');
                        return (
                            <div className="card" key={slug}>
                                <Link
                                    href={`/addiction-talk/${slug}`}
                                    title={`Upcoming: ${title}`}>
                                    <div className="card__image">
                                        <Image
                                            src={
                                                episode_cover_image ||
                                                'https://admin.americanaddictioncenters.org/wp-content/uploads/2021/01/Addiction-Talk-LP-Hero-1-1.jpg'
                                            }
                                            width={0}
                                            height={0}
                                            sizes="33vw"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                </Link>
                                <div className="card__details">
                                    <div className="card__header">Upcoming Episode</div>
                                    <div
                                        className="card__title"
                                        dangerouslySetInnerHTML={{__html: title}}
                                    />
                                    <div
                                        className="card__air-date"
                                        dangerouslySetInnerHTML={{
                                            __html: `${date} EDT`,
                                        }}
                                    />
                                    <div
                                        className="card__copy"
                                        dangerouslySetInnerHTML={{
                                            __html: truncate(hero_description, 180),
                                        }}
                                    />
                                    <Link
                                        href={`/addiction-talk/${slug}`}
                                        title={`Meet Our Next Guest`}
                                        style={{
                                            textDecoration: 'none',
                                            color: 'var(--error)',
                                            border: '2px solid var(--error)',
                                            padding: '12px 18px',
                                            borderRadius: '8px',
                                            display: 'block',
                                            maxWidth: 'fit-content',
                                            fontWeight: 'bold',
                                        }}>
                                        Meet Our Next Guest
                                    </Link>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <style jsx>
                {`
                    .container {
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .container {
                            margin-bottom: 80px;
                        }
                    }
                    .card {
                        display: grid;
                        grid-template-columns: 1fr;
                        padding: 0 15px 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .card {
                            grid-template-columns: 480px auto;
                            grid-gap: 30px;
                            margin-bottom: 40px;
                            padding: 0;
                        }
                    }
                    .card__header {
                        color: var(--error);
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-bottom: 8px;
                        font-size: 14px;
                        padding: 8px 0;
                    }
                    .card__image {
                        position: relative;
                        width: 100%;
                        background: #000;
                        border-radius: 8px;
                        box-shadow: var(--box-shadow);
                        overflow: hidden;
                    }
                    .card__title {
                        font-size: 30px;
                        font-weight: bold;
                        margin-bottom: 4px;
                    }
                    .card__air-date {
                        font-size: 20px;
                        margin-bottom: 16px;
                    }
                    .card__copy {
                        margin-bottom: 16px;
                        line-height: 24px;
                    }
                    a {
                        text-decoration: none;
                        color: var(--error);
                        border: 2px solid var(--error);
                        padding: 12px 18px;
                        border-radius: 8px;
                        display: block;
                        max-width: fit-content;
                        font-weight: bold;
                    }
                `}
            </style>
        </MaxWidth>
    );
};
export default UpcomingEpisodes;
